<script setup lang="ts">
import { setI18nLanguage, useI18n } from '@/i18n';
import { createLogger } from '@/utils';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

interface Emits {
  (e: 'select'): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();
const router = useRouter();
const logger = createLogger('LocaleSelect');

const locales = computed(() => ({
  ru: t('locales.ru'),
  en: t('locales.en'),
}));

async function handleSelect(locale: string) {
  try {
    await setI18nLanguage(locale);

    emit('select');

    router.replace({ params: { locale } });
  } catch (e) {
    logger.error(e);
    router.push('/');
  }
}
</script>

<template>
  <div class="locale-select">
    <popper
      :interactive="false"
      placement="bottom-end"
      offset-distance="10"
      arrow
    >
      <slot />

      <template #content>
        <ul class="locale-select__list">
          <li
            v-for="(name, locale) in locales"
            :key="locale"
          >
            <d-button
              class="locale-select__button"
              mode="white"
              size="medium"
              flat
              @click="handleSelect(locale)"
            >
              <template #icon>
                <img
                  width="24"
                  height="24"
                  :alt="locale"
                  :src="`/assets/flags/${locale}.svg`"
                >
              </template>

              {{ name }}
            </d-button>
          </li>
        </ul>
      </template>
    </popper>
  </div>
</template>
