<script setup lang="ts">
import type { Ref } from 'vue';
import { useI18n } from '@/i18n';
import { getPasswordRules } from '@/utils/validator/rules/password';
import { useDebounceFn } from '@vueuse/core';
import { useField } from 'vee-validate';
import { computed, ref, toRef, watch } from 'vue';

/// // props /////

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '#18191d',
  },
  appendIcon: {
    type: [String, Array],
    default: '',
  },
  appendIconTooltip: {
    type: String,
    default: '',
  },
  appendIconDisabled: {
    type: Boolean,
    default: false,
  },
  withHint: {
    type: Boolean,
    default: false,
  },
  debounce: {
    type: Number,
    default: 0,
  },
});

/// // emits /////

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'click:append-icon'): void;
}>();

const {
  passwordLengthRule,
  passwordCharactersRule,
  passwordSpecialCharacterRule,
} = getPasswordRules();

export interface InputComponent {
  input: Ref<HTMLInputElement | null>;
}

const { t, locale } = useI18n();

/// // utils (store/route/router) /////

const input = ref<HTMLInputElement | null>(null);
const name = toRef(props, 'name');
const {
  errorMessage,
  validate,
  meta,
  value: inputValue,
} = useField(name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});

defineExpose<InputComponent>({ input });

/// // variables /////

const isPasswordVisible = ref(false);
const focused = ref(false);
const rulesHints = computed(() => [
  {
    text: t('input.hints.password.length'),
    rule: passwordLengthRule,
  },
  {
    text: t('input.hints.password.characters'),
    rule: passwordCharactersRule,
  },
  {
    text: t('input.hints.password.special-character'),
    rule: passwordSpecialCharacterRule,
  },
]);

/// // computed from component /////

const isPassword = computed(() => {
  return props.type === 'password';
});
const inputType = computed(() => {
  if (isPassword.value) {
    return isPasswordVisible.value ? 'text' : 'password';
  }

  return props.type;
});
const eyeIcon = computed(() => {
  const name = isPasswordVisible.value ? 'eye-slash' : 'eye';
  return ['fas', name];
});
const toggleButtonTooltip = computed(() => {
  return isPasswordVisible.value
    ? t('input.toggle-button.tooltip.hide-password')
    : t('input.toggle-button.tooltip.show-password');
});
const classes = computed(() => {
  return [
    'text-input',
    `text-input--${props.type}`,
    {
      'has-error': !!errorMessage.value,
      'success': meta.valid,
    },
  ];
});
const showRulesHint = computed(
  () =>
    props.withHint
    && isPassword.value
    && focused.value
    && inputValue.value.length > 0,
);

/// // methods /////

function updateValue(event: Event) {
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.value);
}
const handleInput = useDebounceFn(updateValue, props.debounce);

function handleBlur() {
  validate();
  focused.value = false;
}
function handleFocus() {
  focused.value = true;
}
function togglePasswordVisibility() {
  isPasswordVisible.value = !isPasswordVisible.value;
}

watch(locale, () => {
  if (errorMessage.value) validate();
});
</script>

<template>
  <div :class="classes">
    <v-label
      v-if="label"
      :for="name"
    >
      {{ label }}
    </v-label>
    <div class="text-input__inner">
      <input
        v-bind="$attrs"
        :id="name"
        ref="input"
        :name="name"
        :type="inputType"
        :placeholder="placeholder"
        :value="inputValue"
        @input="handleInput"
        @blur="handleBlur"
        @focus="handleFocus"
      >
      <d-tooltip
        v-if="appendIcon"
        offset-distance="0"
        :text="appendIconTooltip"
      >
        <button
          class="text-input__append-icon"
          type="button"
          :disabled="appendIconDisabled"
          @click="$emit('click:append-icon')"
        >
          <fa-icon :icon="appendIcon" />
        </button>
      </d-tooltip>
      <d-tooltip
        v-else-if="isPassword"
        offset-distance="0"
        :text="toggleButtonTooltip"
      >
        <button
          class="text-input__append-icon"
          type="button"
          :disabled="appendIconDisabled"
          @click="togglePasswordVisibility"
        >
          <fa-icon :icon="eyeIcon" />
        </button>
      </d-tooltip>

      <transition name="rules-hint">
        <div
          v-if="showRulesHint"
          class="rules-hint"
        >
          <ul class="rules-hint__list">
            <li
              v-for="{ text, rule } in rulesHints"
              :key="text"
              class="rules-hint__list-item"
            >
              <fa-icon
                class="rules-hint__list-item-icon"
                :color="
                  rule.isValidSync(inputValue)
                    ? '#1871F8'
                    : '#616266'
                "
                :icon="['fas', 'check-circle']"
              />
              <span>{{ text }}</span>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <p
      v-show="!showRulesHint && (errorMessage || meta.valid)"
      class="help-message"
    >
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.text-input {
  &__append-icon {
    width: 60px;
    height: 58px;
    color: #616266;
    font-size: 16px;

    &:disabled {
      cursor: default;
    }
  }
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px v-bind(bgColor) inset !important;
    -webkit-text-fill-color: #fff !important;
    caret-color: #fff;
  }
}
</style>
