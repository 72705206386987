import { MeetingMode, MeetingTypeEnum } from '../api/meetings/types';

export const meetingModeMapping: Record<MeetingTypeEnum, MeetingMode> = {
  [MeetingTypeEnum.DEFAULT_MEETING]: MeetingMode.RegularMeeting,
  [MeetingTypeEnum.CLIENT_MEETING]: MeetingMode.ClientMeeting,
  [MeetingTypeEnum.HR_INTERVIEW]: MeetingMode.HRInterview,
  [MeetingTypeEnum.TEAM_MEETING]: MeetingMode.TeamMeeting,
  [MeetingTypeEnum.OUTLINE]: MeetingMode.LectureNotes,
  [MeetingTypeEnum.INCOGNITO]: MeetingMode.Incognito,
};
