import type { LobbyStatus } from '@/interfaces/webinar';

export interface CreateMeetingPayload {
  name: string;
  startDateTime: string;
  is_private: boolean;
  mode: MeetingMode;
}

export interface CreateMeetingResponse {
  meeting: Meeting;
}

export enum MeetingTypeEnum {
  DEFAULT_MEETING = 'обычная_встреча',
  CLIENT_MEETING = 'встреча_с_клиентом',
  HR_INTERVIEW = 'HR_интервью',
  TEAM_MEETING = 'командное_совещание',
  OUTLINE = 'конспект',
  INCOGNITO = 'инкогнито',
}

export enum MeetingMode {
  RegularMeeting = 'regular-meeting',
  ClientMeeting = 'client-meeting',
  HRInterview = 'hr-interview',
  TeamMeeting = 'team-meeting',
  LectureNotes = 'lecture-notes',
  Incognito = 'incognito',
}

export interface Meeting {
  id: string;
  creator_id: number;
  name: string;
  start_date_time: string;
  updatedAt: string;
  createdAt: string;
  is_private: boolean;
  is_speech_text_active: boolean;
  status: 'planned' | 'in_progress' | 'ended';
  meeting_type: MeetingTypeEnum;
  invitedMembers: string[] | null;
}

export interface MeetingUser {
  user_id: number;
  meeting_id: string;
  isMe?: boolean;
}

export interface PaginatedMeetings {
  meetingsList: Meeting[];
  count: number;
}

export interface UpdateMeetingPayload {
  id: string;
  name: string;
  start_date_time: string;
  is_private: boolean;
  mode: MeetingMode;
}

export interface ToggleMemberStatusPayload {
  memberId: number;
  status: LobbyStatus;
  nickname: string;
}

export interface MeetingSession {
  id: number;
  start: string;
  end: string | null;
  meeting_id: string;
}

export interface MeetingSessions {
  id: number;
  start: string;
  end: string | null;
  meeting_id: string;
  date: string;
  time: string;
}
