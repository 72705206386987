<script lang="ts" setup>
import type { Breakpoint } from '@/interfaces/utils';
import { useBreakpoints } from '@/composables';
import { computed, defineAsyncComponent } from 'vue';

type Variant =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'light'
  | 'gray'
  | 'dark'
  | 'orange'
  | 'danger'
  | 'success';

interface Props {
  size?: Breakpoint;
  variant?: Variant;
  shape?: 'default' | 'icon' | 'circle-icon';
  type?: HTMLButtonElement['type'];
  pending?: boolean;
  label?: string;
  href?: string;
  to?: string;
  stretch?: Breakpoint | boolean;
  disabled?: boolean;
  iconRight?: string | string[];
  iconLeft?: string | string[];
  flat?: boolean;
  outlined?: boolean;
  rotate?: number | string;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
  variant: 'primary',
  shape: 'default',
  type: 'button',
  stretch: false,
  pending: false,
  disabled: false,
  flat: false,
  outlined: false,
  label: undefined,
  href: undefined,
  to: undefined,
  iconRight: undefined,
  iconLeft: undefined,
});

const slots = defineSlots<{
  'icon-right'?: () => unknown;
  'default'?: () => unknown;
  'icon-left'?: () => unknown;
}>();

const DLink = defineAsyncComponent(() => import('../DLink.vue'));

const breakpoints = useBreakpoints({ strategy: 'min-width' });

const component = computed(() =>
  props.to ? DLink : props.href ? 'a' : 'button',
);

const type = computed(() => (props.href || props.to ? null : props.type));
const disabled = computed(() => props.disabled || props.pending);
const stretch
    = typeof props.stretch === 'boolean'
      ? computed(() => props.stretch)
      : breakpoints.smaller(props.stretch);

const isDefaultShape = computed(() => props.shape === 'default');

const hasRightIcon = computed(() => slots['icon-right'] || props.iconRight);
const hasLeftIcon = computed(() => slots['icon-left'] || props.iconLeft);

const classes = computed(() => [
  'ui-button',
  `ui-button--${props.size}`,
  `ui-button--${props.variant}`,
  `ui-button--${props.shape}`,
  {
    'ui-button--flat': props.flat,
    'ui-button--outlined': props.outlined,
    'ui-button--icon-right': isDefaultShape.value && hasRightIcon.value,
    'ui-button--icon-left': isDefaultShape.value && hasLeftIcon.value,
    'ui-button--stretch': stretch.value,
  },
]);
</script>

<template>
  <component
    :is="component"
    :class="classes"
    :to="to"
    :href="href"
    :type="type"
    :disabled="disabled"
    :aria-disabled="disabled"
  >
    <span
      v-if="pending"
      class="ui-button__icon"
    >
      <fa-icon
        :icon="['fas', 'circle-notch']"
        spin
      />
    </span>

    <template v-else>
      <span
        v-if="hasLeftIcon"
        class="ui-button__icon"
        :style="{
          transform: rotate ? `rotate(${rotate}deg)` : undefined,
        }"
      >
        <slot name="icon-left">
          <FaIcon :icon="iconLeft" />
        </slot>
      </span>

      <span
        v-if="isDefaultShape"
        class="ui-button__label"
      >
        <slot>{{ label }}</slot>
      </span>

      <span
        v-if="hasRightIcon"
        class="ui-button__icon"
        :style="{
          transform: rotate ? `rotate(${rotate}deg)` : undefined,
        }"
      >
        <slot name="icon-right">
          <FaIcon :icon="iconRight" />
        </slot>
      </span>
    </template>
  </component>
</template>

<style lang="scss">
.ui-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  transition:
    color,
    background-color,
    opacity 0.2s ease;

  @include for-mouse-based-devices {
    &:hover {
      opacity: 0.7;
    }
  }

  &:active:not(:disabled) {
    opacity: 0.7;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
  }

  &--stretch {
    display: flex;
    width: 100%;
  }

  &--xs {
    border-radius: 10px;
  }

  &--sm {
    padding: 15px 30px;
    border-radius: 15px;
  }

  &--lg {
    padding: 20px 30px;
    border-radius: 20px;
  }

  &--primary {
    background-color: $desktop-main-blue;
    color: $white;
  }

  &--secondary {
    background-color: $black-3;
    color: $white-light;
  }

  &--orange {
    background-color: $desktop-yellow;
    color: $white;
  }

  &--white {
    background-color: $white;
    color: $black-default;
  }

  &--light {
    background-color: $dark-gray;
    color: $white-light;
  }

  &--gray {
    color: $light-gray;
    background-color: $black-gray;
  }

  &--dark {
    background-color: $black-default;
    color: $white;
  }

  &--danger {
    background-color: $desktop-red;
    color: $white;
  }

  &--success {
    background-color: $desktop-green;
    color: $white;
  }

  &--flat {
    background-color: hsla(0, 0%, 100%, 0);

    &.ui-button {
      &--primary {
        color: $desktop-main-blue;
      }

      &--secondary {
        color: $black-3;
      }

      &--orange {
        color: $desktop-yellow;
      }

      &--white {
        color: $light-gray;
      }

      &--light {
        color: $dark-gray;
      }

      &--gray {
        color: $black-gray;
      }

      &--dark {
        color: $black-default;
      }

      &--danger {
        color: $desktop-red;
      }

      &--success {
        color: $desktop-green;
      }
    }
  }

  &--outlined {
    border: 2px solid currentColor;
  }

  &--circle-icon {
    border-radius: 50%;
  }

  &:where(&--icon, &--circle-icon) {
    &.ui-button {
      &--xs {
        padding: 12px;
      }

      &--sm {
        padding: 15px;
      }

      &--lg {
        padding: 20px;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    transition: transform 0.3s ease;
  }

  &--xs &__icon {
    font-size: 16px;
  }

  &--sm &__icon {
    font-size: 20px;
  }

  &--lg &__icon {
    font-size: 30px;
  }

  &--icon-right &__label {
    margin-right: 8px;
  }

  &--icon-left &__label {
    margin-left: 8px;
  }
}
</style>
