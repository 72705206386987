<script setup lang="ts">
import DPopperInfo from '../DPopperInfo.vue';
import UiCaption from './UiCaption.vue';

interface Props {
  text?: string;
  plain?: boolean;
}

withDefaults(defineProps<Props>(), {
  text: undefined,
  plain: true,
});

const slots = defineSlots<{
  popper?: () => unknown;
}>();
</script>

<template>
  <label
    class="ui-label"
    :class="[{ 'ui-label--plain': plain }]"
  >
    <UiCaption
      tag="span"
      weight="bold"
      :text="text"
    />

    <DPopperInfo
      v-if="slots.popper"
      max-width="665px"
      hide-icon
    >
      <slot name="popper" />
    </DPopperInfo>
  </label>
</template>

<style lang="scss">
.ui-label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 13px 20px;
  border-radius: 15px;
  background-color: $black-2;

  &--plain {
    background-color: transparent;
  }
}
</style>
