<script setup lang="ts">
import { computed, ref } from 'vue';
import { VTooltip } from 'vuetify/components';

interface Props {
  variant?: 'primary' | 'secondary';
  text?: string;
  disabled?: boolean;
  offsetDistance?: string;
  placement?: VTooltip['location'];
  activatorClass?: string;
  openOnClick?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  placement: 'bottom',
  offsetDistance: '18',
  activatorClass: '',
  text: '',
  openOnClick: false
});

const slots = defineSlots<{
  default?: () => unknown;
  content?: () => unknown;
}>();

const modelValue = ref(false);

const isDisabled = computed(
  () => props.disabled || (!props.text && !slots.content),
);
</script>

<template>
  <VTooltip
    v-model="modelValue"
    :class="`tooltip tooltip--${variant}`"
    content-class="tooltip__content"
    scroll-strategy="close"
    :offset="offsetDistance"
    :location="placement"
    :disabled="isDisabled"
    :interactive="false"
    arrow
    hover
  >
    <template #activator="{ props }">
      <div
        v-bind="props"
        :class="`tooltip__activator ${activatorClass}`"
        @click="modelValue = openOnClick"
      >
        <slot name="default" />
      </div>
    </template>

    <slot name="content">
      {{ text }}
    </slot>
  </VTooltip>
</template>

<style lang="scss">
.tooltip > .tooltip__content {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  color: $white;
}

.tooltip--primary > .tooltip__content {
  padding: 15px 14px;
  background-color: $black-gray;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
}

.tooltip--secondary > .tooltip__content {
  padding: 15px;
  border-radius: 30px;
  border: 1px solid #333;
  background: rgba(9, 9, 11, 0.2);
  backdrop-filter: blur(30px);
  box-shadow:
    0px 80px 80px 0px rgba(0, 0, 0, 0.25),
    0px 26px 30px 0px rgba(0, 0, 0, 0.15);
}
</style>
