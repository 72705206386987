<script lang="ts" setup>
import AppNotifications from '@/blocks/AppNotifications.vue';
import LocaleSelectButton from '@/blocks/LocaleSelectButton.vue';
import { defineAsyncComponent } from 'vue';
import { ModalsContainer } from 'vue-final-modal';

const LoadingScreen = defineAsyncComponent(
  () => import('@/blocks/LoadingScreen.vue'),
);
</script>

<template>
  <VApp class="dark">
    <VMain>
      <RouterView />
    </VMain>

    <LocaleSelectButton />

    <AppNotifications />

    <ModalsContainer />

    <LoadingScreen />
  </VApp>
</template>

<style lang="scss">
@import './assets/styles/styles.scss';
</style>
