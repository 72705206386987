<script lang="ts" setup>
import LocaleSelect from '@/blocks/LocaleSelect.vue';
import { UiButton } from '@/components/v2';
import { useI18n } from '@/i18n';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const { locale } = useI18n();
const route = useRoute();

const localeFlag = computed(() => `/assets/flags/${locale.value}.svg`);
const isVisible = computed(() => route.name !== 'Rooms');
</script>

<template>
  <LocaleSelect
    v-show="isVisible"
    class="locale-button"
  >
    <UiButton
      class="pa-0"
      shape="circle-icon"
      size="lg"
      flat
    >
      <template #icon-left>
        <img
          :src="localeFlag"
          :alt="locale"
        >
      </template>
    </UiButton>
  </LocaleSelect>
</template>

<style scoped lang="scss">
.locale-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99;
}
</style>
