import type {
  UseBreakpointsOptions
} from '@vueuse/core';
import { BREAKPOINTS } from '@/constants';
import {
  useBreakpoints as useVueBreakpoints,
} from '@vueuse/core';

export const useBreakpoints = (options?: UseBreakpointsOptions) =>
  useVueBreakpoints(BREAKPOINTS, options);
