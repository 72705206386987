import i18n from '@/i18n';
import { string } from 'yup';
import requiredRule from './required';

const { t } = i18n.global;

const domainBeforeDotSchema = string().matches(
  /^(?=.*[A-Za-zА-Яа-я])[A-Za-zА-Яа-я\d](?!.*[._-]$)[\wА-Яа-я-]{0,30}(?!^[._-])[A-Za-zА-Яа-я\d]$/,
  { message: () => t('form.validation.regex') },
);

export default (required = true) =>
  requiredRule(required)
    .matches(
      /^[A-Za-z\d](?!.*[._-]$)[\w.-]{0,62}(?!^[._-])[A-Za-z\d]@[A-Za-z\d](?!.*[_-]$)[\w-]{0,30}(?!^[_-])[A-Za-z\d]\.([a-zа-я]{2,16})$/,
    )
    .test(
      'domain-before-dot-validation',
      () => t('form.validation.regex'),
      (value) => {
        if (!value) return;

        const [, domainBeforeDot] = value.split('@');
        if (!domainBeforeDot) return;
        const [domain] = domainBeforeDot.split('.');
        if (!domain) return;
        return domainBeforeDotSchema.isValidSync(domain);
      },
    );
